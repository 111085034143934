var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.errors),function(error,i){return _c('v-alert',{key:i,attrs:{"type":"error"}},[_vm._v(" "+_vm._s(error.message || error)+" ")])}),_c('div',{staticClass:"d-flex flex-row flex-nowrap justify-end align-center ma-4",staticStyle:{"position":"absolute","top":"0px","right":"0px","z-index":"1"}},[_c('v-btn',{attrs:{"icon":"","to":{ name: 'dashboard-payout-detail' }}},[_c('v-icon',[_vm._v("mdi-table")])],1),_c('v-btn',{staticClass:"white",staticStyle:{"z-index":"1"},attrs:{"icon":""},on:{"click":function($event){return _vm.getData()}}},[_c('v-icon',[_vm._v("$refresh")])],1)],1),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFetching),expression:"isFetching"}],staticClass:"mx-0 mt-0",staticStyle:{"margin-bottom":"-4px"},attrs:{"color":"secondary","indeterminate":"","height":"4"}}),_c('v-container',[(_vm.categoryGroups.length === 0)?_c('div',{staticClass:"text-center pa-4 text--disabled"},[_vm._v(" no data ")]):_c('v-row',{attrs:{"justify":"center"}},[_vm._l((_vm.categoryGroups),function(ref,index){
var id = ref.id;
var groupItems = ref.group;
var categoryItems = ref.items;
return [(index > 0)?_c('v-col',{key:("divider-" + index),staticClass:"px-0",attrs:{"cols":"12"}},[_c('v-divider')],1):_vm._e(),_c('v-col',{key:("title-" + id),attrs:{"cols":"12"}},[_c('h2',{staticClass:"mt-4 mb-2 d-flex align-center"},[_c('v-badge',{attrs:{"avatar":"","bordered":"","overlap":"","color":"white","bottom":"","right":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-avatar',[_c('v-img',{attrs:{"src":groupItems.company.logo,"contain":""}})],1)]},proxy:true}],null,true)},[_c('v-avatar',[_c('img',{attrs:{"src":groupItems.salesperson.avatarUrl,"alt":groupItems.salesperson.username}})])],1),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s([ groupItems.company.abbr, groupItems.salesArea.abbr, groupItems.salesperson.username, groupItems.commissionQuotaPeriod.abbr, groupItems.currency ].join(' | '))+" ")])],1)]),_c('v-col',{key:("data-" + id),staticClass:"px-0"},[_c('commission-payout-table',{attrs:{"items":categoryItems,"all-items":categoryItems,"group":_vm.group,"child-groups":[
              'commissionComponentGroup',
              'commissionComponent' ],"hide-columns":[
              'company.abbr',
              'salesArea.abbr',
              'salesperson.username',
              'commissionQuotaPeriod.abbr',
              'currency' ]}})],1)]})],2)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }