






































































































import Vue from 'vue'
import log from '@/log'

// components
import CommissionPayoutTable from '@/components/dashboard/payout/partials/CommissionPayoutTable.vue'

// table data
import { config } from './table-data'
import { CommissionQuotaPeriod, Company, SalesArea, Salesperson } from '@/types'
import { TableGrouperItem } from '@/utils/table-grouper'

const { group } = config

interface Group {
  id: string
  group: {
    company: Company
    salesArea: SalesArea
    salesperson: Salesperson
    commissionQuotaPeriod: CommissionQuotaPeriod
    currency: string
  }
  items: TableGrouperItem[]
}

export default Vue.extend({
  metaInfo: {
    title: 'Payout - Dashboard',
  },
  components: {
    CommissionPayoutTable,
  },
  props: {
    period: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  data: () => ({
    items: [] as TableGrouperItem[],
    group,

    isFetching: false,
    errors: [] as Error[],
  }),
  watch: {
    period: {
      immediate: true,
      handler() {
        this.getData()
      },
    },
  },
  computed: {
    categoryGroups(): unknown {
      return this.items.reduce((prev: Group[], item) => {
        // CO	SALES AREA	SALESPERSON	PERIOD	COMPONENT	CURRENCY
        let g = prev.find(
          p =>
            p.group.company.id === item.company.id &&
            p.group.salesArea.id === item.salesArea.id &&
            p.group.salesperson.id === item.salesperson.id &&
            p.group.commissionQuotaPeriod.id ===
              item.commissionQuotaPeriod.id &&
            p.group.currency === item.currency
        )

        if (!g) {
          g = {
            id: [
              item.company.id,
              item.salesArea.id,
              item.salesperson.id,
              item.commissionQuotaPeriod.id,
              item.currency,
            ].join(':'),
            group: {
              company: item.company,
              salesArea: item.salesArea,
              salesperson: item.salesperson,
              commissionQuotaPeriod: item.commissionQuotaPeriod,
              currency: item.currency,
            },
            items: [],
          }

          prev.push(g)
        }

        g.items.push(item)

        return prev
      }, [])
    },
  },
  methods: {
    getData(): void {
      this.isFetching = true
      this.errors = []

      this.$api.commissions.payouts.periods
        .period(this.period)
        .list()
        .then(items => {
          this.items = items
        })
        .catch(err => {
          log.error(err)
          this.errors.push(err)
        })
        .finally(() => {
          this.isFetching = false
        })
    },
  },
})
